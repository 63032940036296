import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs';
import { LogingServiceService } from 'src/app/Pages/login-sign-up/loging-service.service';

@Component({
  selector: 'app-mail-reuse',
  templateUrl: './mail-reuse.component.html',
  styleUrls: ['./mail-reuse.component.scss']
})
export class MailReuseComponent implements OnInit {

  constructor(
    private _router: Router,
    private _location: Location,
    private _auth: LogingServiceService
  ) {
    this._router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          setTimeout(() => {
            this.goToLogin();
          }, 0);
        }
      });
  }

  ngOnInit(): void {

  }

  goToLogin() {
    this._location.replaceState(this._location.path().split('?')[0], '');
    this._auth.backToLogin();
  }

}
