import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DEFAULT } from 'src/app/core/constants/app.constant';
import { GenericService } from 'src/app/core/services/generic.service';
import { CarousalService } from './carousal.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnChanges {

  // titleText: boolean = false;
  carousalItems: any = [];
  default_banner = DEFAULT.DUMMY_BLANK_IMAGE;
  @Input() activity_id: any;
  @Input() category_id: any;
  @Input() vendor_id: any;


  constructor(
    private router: ActivatedRoute,
    private carousal: CarousalService,
    private generic: GenericService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activity_id || changes.category_id || changes.vendor_id) {
      this.getCarousalDetails();
    }
  }

  ngOnInit(): void {
    this.getCarousalDetails();
  }

  @HostListener('window:resize', ['$event'])
  setDynamicImages(event?) {
    this.generic.setDynamicImages(this.carousalItems, 'images', 'banner_image', this.default_banner);
  }

  getCarousalDetails() {
    this.carousal.getCarousalDetails(this.activity_id, this.category_id, this.vendor_id).subscribe((res: any) => {
      if (res) {
        this.carousalItems = [];
        for (let i = 0; i < res.length; i++) {
          let obj = {
            images: res[i]
          }
          this.carousalItems.push(obj);
        }
        this.setDynamicImages();
      }
    },
      (err) => {

      })
  }

}
