import { Component, TemplateRef } from '@angular/core';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from './toast.service';

@Component({
	selector: 'app-toasts',
	standalone: true,
	imports: [NgbToastModule, NgIf, NgTemplateOutlet, NgFor],
	styleUrls: ['./toasts-container.component.scss'],
	template: `
		<ngb-toast
			*ngFor="let toast of toastService.toasts"
			[class]="toast.classname"
			[autohide]="true"
			[delay]="toast.delay || 5000"
			(hidden)="toastService.remove(toast)"
		>
			<section class="icon_body_outer">
				<div class="closeIconOuter" (click)="toastService.remove(toast)">
					<mat-icon class="material-icons">close</mat-icon>
				</div>
				<div class="icon_part">
					<mat-icon class="material-icons mat_toast_icon">
					{{toast.status === 'success' ? 'done' : toast.status === 'error' ? 'error' : toast.status === 'warning' ? 'warning': 'notification_important'}}
					</mat-icon>
				</div>
				<div class="text_part">
					<p class="alert_text">
						{{toast.head}}
					</p>
					<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
						<ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
					</ng-template>
					<ng-template #text>{{ toast.textOrTpl }}</ng-template>
				</div>
			</section>
		</ngb-toast>
	`,
	host: { class: 'toast-container position-fixed top-0 p-3' }
})
export class ToastsContainer {
	constructor(public toastService: ToastService) { }

	isTemplate(toast) {
		return toast.textOrTpl instanceof TemplateRef;
	}
}