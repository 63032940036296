import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WildCardPageComponent } from './Pages/wild-card-page/wild-card-page.component';
import { MailReuseComponent } from './shared/components/mail-reuse/mail-reuse.component';
import { RedirectUiComponent } from './Pages/redirect-ui/redirect-ui.component';
import { AuthGuard } from './core/auth.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./Pages/login-sign-up/login-sign-up.module')
      .then(m => m.LoginSignUpModule)
  },

  {
    path: 'activity',
    loadChildren: () => import('./Pages/activity/activity.module')
      .then(m => m.ActivityModule)
  },

  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Pages/settings/settings.module')
      .then(m => m.SettingsModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./Pages/homepage/homepage.module')
      .then(m => m.HomepageModule)
  },

  {
    path: 'vendor',
    loadChildren: () => import('./Pages/vendor/vendor.module')
      .then(m => m.VendorModule)
  },

  {
    path: 'chat',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Pages/chat/chat.module')
      .then(m => m.ChatModule)
  },

  {
    path: 'listing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Pages/activity-listing/activity-listing.module')
      .then(m => m.ActivityListingModule)
  },

  {
    path: 'mail-confirmation',
    component: MailReuseComponent
  },

  {
    path: 'redirect-to',
    component: RedirectUiComponent
  },

  {
    path: 'verify-email',
    component: RedirectUiComponent
  },

  {
    path: '**', component: WildCardPageComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
