<div class="carousel" data-aos="fade-right" data-aos-delay="400" data-aos-duration="800" data-aos-easing="linear">
  <div class="container">
    <div id="carousel-main" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000">
      <ol class="carousel-indicators">
        <li data-target="#carousel-main" [attr.data-slide-to]="i" *ngFor="let banner of carousalItems; index as i"
          [ngClass]="{'active':i === 0}"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item" *ngFor="let banner of carousalItems; index as i" [ngClass]="{'active':i === 0}">
          <div class="view">
            <img [src]="banner?.banner_image" [alt]="i+' image'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>