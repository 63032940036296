import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieServices {

  constructor(private cookie: CookieService) { }

  setCookie(key, value) {
    this.cookie.set(key, value);
  }

  deleteCookie(key){
    this.cookie.delete(key);
  }

  deleteAll () {
    this.cookie.deleteAll();
  }

  getCookie(key) {
    return this.cookie.get(key);
  }

  getAllCookie() {
    return this.cookie.getAll();
  }
}
