import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'src/app/core/constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private http: HttpClient) { }

  getListOfCategories(isPopular?) {
    let url = `${API.get_list_of_categories}`;
    if (isPopular) {
      url = url + `?popular=${isPopular}`;
    }
    return this.http.get(url);
  }


}
