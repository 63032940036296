import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  successToast(text: string, head?: string) {
    this.show(text, {
      status: 'success',
      head: head ? head : 'success!',
      classname: 'toast_success'
    });
  }

  errorToast(text: string, head?: string) {
    this.show(text, {
      status: 'error',
      head: head ? head : 'error!',
      classname: 'toast_error'
    });
  }

  warningToast(text: string, head?: string) {
    this.show(text, {
      status: 'warning',
      head: head ? head : 'warning!',
      classname: 'toast_warning'
    });
  }

  infoToast(text: string, head?: string) {
    this.show(text, {
      status: 'info',
      head: head ? head : 'info!',
      classname: 'toast_info'
    });
  }

}
