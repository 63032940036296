import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '../constants/api.constant';
import { LogingServiceService } from 'src/app/Pages/login-sign-up/loging-service.service';

@Injectable({
  providedIn: 'root'
})
export class SSEService {

  constructor(
    private _zone: NgZone,
    private _auth: LogingServiceService
  ) { }

  private eventSource: EventSource;

  connect(): Observable<MessageEvent> {
    let token = this._auth.getUserInfo().accessToken;
    let url = `${API.chat_notification_SSE}/${token}`
    this.eventSource = new EventSource(url);

    return new Observable<MessageEvent>((observer) => {
      this.eventSource.addEventListener('message', (event: MessageEvent) => {
        observer.next(event);
      });

      this.eventSource.onerror = (error) => {
        observer.error(error);
      };

      // Clean up the connection when the Observable is unsubscribed
      return () => {
        this.eventSource.close();
      };
    });
  }


  // connect(): Observable<any> {
  //   let token = this._auth.getUserInfo().accessToken;
  //   let url = `${API.chat_notification_SSE}/${token}`
  //   return Observable.create(observer => {
  //     const eventSource = this.getEventSource(url);
  //     eventSource.onmessage = event => {
  //       this._zone.run(() => {
  //         observer.next(event);
  //       });
  //     };
  //     eventSource.onerror = error => {
  //       this._zone.run(() => {
  //         observer.error(error);
  //       });
  //     };
  //   });
  // }
  // private getEventSource(url: string): EventSource {
  //   return new EventSource(url);
  // }


}
