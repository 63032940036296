<div class="footer">
    <div class="container">
        <footer>
            <div class="row text-nowrap">
                <div class="col-lg-4 footerIMG  p-0">
                    <img src="../../assets/Website assets/Logo/Logo_with_tagline.svg" alt="" class="">
                </div>
                <div class="col-lg-1">
                </div>
                <div class="col-lg-2 navLinks">
                    <ul>
                        <li routerLinkActive="activeLi">
                            <span>
                                <a routerLink="/home">
                                    Home
                                </a>
                            </span>
                        </li>
                        <li routerLinkActive="activeLi">
                            <span>
                                <a routerLink="/vendor/home">
                                    Partner with us
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 footLinks">
                    <ul>
                        <li>
                            <span>
                                <a (click)="termsAndPolicy.openTermsPolicyDialog('terms')">
                                    Terms of use
                                </a>
                            </span>
                        </li>
                        <li>
                            <span>
                                <a (click)="termsAndPolicy.openTermsPolicyDialog('policy')">
                                    Privacy policy
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    </div>
</div>