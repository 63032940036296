<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<section class="main_app_outer" [ngClass]="showSpinner?'root_outer_block':''">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" [color]="isLogin ? 'primary' : 'accent'"
        class="mat_loader_custom"></mat-progress-bar>
    <section class="app_navbar" [ngClass]="isScrolled ? 'bodyScrolled' : ''" id="app_header">
        <app-extra-navbar *ngIf="!isLogin"></app-extra-navbar>
        <app-navbar *ngIf="isLogin"></app-navbar>
    </section>
    <section [class]="isLogin ? 'login_body_router_outlet':'logout_body_router_outlet'">
        <router-outlet></router-outlet>
    </section>
    <app-footer></app-footer>
    <mat-spinner *ngIf="showSpinner" diameter="100" strokeWidth="5" [color]="isLogin ? 'primary' : 'accent'"
        class="mat_spinner_custom"></mat-spinner>
</section>