import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogingServiceService } from '../login-sign-up/loging-service.service';
import { ToastService } from 'src/app/core/ngb-toast/toast.service';

@Component({
  selector: 'app-redirect-ui',
  templateUrl: './redirect-ui.component.html',
  styleUrls: ['./redirect-ui.component.scss']
})
export class RedirectUiComponent implements OnInit {

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _auth: LogingServiceService,
    private _toast: ToastService
  ) {

  }

  ngOnInit(): void {
    if (this._router.url.includes('verify-email')) {
      this.verifyEmailValidOrNor();
    }
  }

  private verifyEmailValidOrNor() {
    this._activatedRoute.queryParams.subscribe(
      (params) => {
        if (params) {
          if (params.token) {
            this._auth.backToLogin(true);
            this.isTokenValid(params.token);
          }
        }
      }
    )
  }

  isTokenValid(token) {
    this._auth.checkEmailTokenValidOrNot(token).subscribe((res: any) => {
      this._router.navigate(['mail-confirmation']);
    }, (err) => {
      this._toast.errorToast('Token is not valid or expired!');
      this._router.navigate(['']);
    })
  }


}
