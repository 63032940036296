// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: "https://api.moppetto.com",
  socket_url: "wss://api.moppetto.com/ws",
  vPanelLoginURL: 'https://v.moppetto.com/login',
  firebase: {
    apiKey: "AIzaSyAUyUMPjYNp7mbTuEnxltBzIMwuUN4IC7M",
    authDomain: "moppetto-347106.firebaseapp.com",
    projectId: "moppetto-347106",
    storageBucket: "moppetto-347106.appspot.com",
    messagingSenderId: "454759763830",
    appId: "1:454759763830:web:4c56059b99835b93d2b457"
  },
  app_base_url: "http://localhost:4200"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
