import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable, Subject, BehaviorSubject } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LogingServiceService } from 'src/app/Pages/login-sign-up/loging-service.service';
import { TokenDetails } from '../models/TokenDetails';
import { GenericService } from './generic.service';
import { WebsocketService } from 'src/app/Pages/chat/services/websocket.service';
import { Router } from '@angular/router';
import { ToastService } from '../ngb-toast/toast.service';

@Injectable(
  // {
  // providedIn: 'root'
  // }
)
export class AuthInterceptorService implements HttpInterceptor {

  // baseUrl = environment.base_url;

  // constructor(
  //   private _auth: LogingServiceService
  // ) { }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   var token = this._auth.getUserInfo().accessToken;

  //   if (token) {
  //     request = request.clone({
  //       url: `${request.url}`,
  //       setHeaders: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     });
  //   } else {
  //     request = request.clone({
  //       url: `${request.url}`
  //     });
  //   }

  //   return next.handle(request).pipe(
  //     catchError(this.handleError)
  //   );

  // }

  // handleError(error: HttpErrorResponse) {
  //   return throwError(error);
  // }

  // private userInfo: TokenDetails;

  constructor(
    private _auth: LogingServiceService,
    private _generic: GenericService,
    private _socket: WebsocketService,
    private _router: Router,
    private _toast: ToastService
  ) {
    // this.userInfo = this._auth.getUserInfo();
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   let authReq = request;
  //   // debugger
  //   if (this.userInfo.accessToken !== null) {
  //     authReq = this.updateHeader(authReq)
  //   }
  //   return next.handle(authReq).pipe(
  //     map((event: HttpEvent<any>) => {
  //       return event;
  //     }),
  //     catchError((error, caught) => {
  //       if (error instanceof HttpErrorResponse) {
  //         if (this._checkTokenExpiryErr(error)) {
  //           this.userInfo.accessToken = null;
  //           this._auth.setLoginDetailsToLocalStorage(this.userInfo)
  //           return this._ifTokenExpired().pipe(
  //             switchMap(() => {
  //               let authReq = request;
  //               this.userInfo = this._auth.getUserInfo();
  //               if (this.userInfo.accessToken !== null) {
  //                 authReq = this.updateHeader(authReq)
  //               }
  //               return next.handle(authReq).pipe(
  //                 map((event: HttpEvent<any>) => {
  //                   return event;
  //                 })
  //               )
  //             })
  //           );
  //         } else {
  //           return throwError(error);
  //         }
  //       }
  //       return caught;
  //     })
  //   );
  // }

  // private _ifTokenExpired() {
  //   let body = {
  //     refresh: this.userInfo.refreshToken,
  //   }
  //   return this._auth.refreshToken2(body);
  // }

  // private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
  //   return error.status && error.status === 401;
  // }

  // updateHeader(req) {
  //   if (this.userInfo.accessToken !== null) {
  //     req = req.clone({
  //       headers: req.headers.set("Authorization", `Bearer ${this.userInfo.accessToken}`)
  //     });
  //   }
  //   return req;
  // }

  // updateHeaderTemp(req) {
  //   req = req.clone({
  //     headers: req.headers,
  //   });
  //   return req;
  // }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private specialURL = 'authentication/token/refresh'
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this._auth.getUserInfo().accessToken;
    if (token) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(catchError(error => {
      if (error.url.includes(this.specialURL)) {
        this._auth.backToLogin();
        return throwError(error);
      }
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (!error.url.includes(this.specialURL)) {
          return this.handle401Error(authReq, next);
        }
      }
      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this._auth.getUserInfo().refreshToken;
      if (!token) this._auth.backToLogin();
      let userInfo = this._auth.getUserInfo();
      delete userInfo.accessToken;
      this._auth.setLoginDetailsToLocalStorage(userInfo);
      return this._auth.refreshToken(token).pipe(
        switchMap((data: any) => {
          this.isRefreshing = false;
          let userInfo = this._auth.getUserInfo();
          userInfo.accessToken = data['tokens']['access'];
          userInfo.refreshToken = data['tokens']['refresh'];
          this._auth.setLoginDetailsToLocalStorage(userInfo);
          this.refreshTokenSubject.next(userInfo.accessToken);
          return next.handle(this.addTokenHeader(request, userInfo.accessToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this._auth.backToLogin();
          this._toast.errorToast('Your session has expired, please login!', 'Login');
          this._generic.spinnerTriggered(false);
          localStorage.setItem('shared_url', this._router.url);
          return throwError(err);
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token),
      take(1),
      switchMap((token) => {
        return next.handle(this.addTokenHeader(request, token))
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }


}



