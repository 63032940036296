import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { API } from 'src/app/core/constants/api.constant';
import { LogingServiceService } from '../../login-sign-up/loging-service.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket$: WebSocketSubject<any>;
  private profileSocket$: WebSocketSubject<any>;
  private profileSocketSA$: WebSocketSubject<any>;
  private notificationSocket$: WebSocketSubject<any>;

  constructor(
    private _auth: LogingServiceService
  ) {
    // this.connectProfileDetails();
  }

  public connectAllSocket() {
    this.connectNotificationSocket();
    this.connectProfileDetails();
    this.connectProfileDetailsForSA();
    this.connectChatSocket();
  }

  public connectChatSocket(chat_type?: string): void {
    const type = chat_type ? chat_type : 'activity_chat';
    const url = `${API.chat_socket}${type}/`;
    const token = this._auth.getUserInfo().accessToken;
    const webSocketUrl = `${url}${encodeURIComponent(token)}/`;
    this.socket$ = webSocket(webSocketUrl);
  }

  public sendMessage(message: any): void {
    this.socket$.next(message);
  }

  public receiveMessage(): Observable<any> {
    return this.socket$.asObservable();
  }

  public isSocketConnected(chat_type): any {
    if (this.socket$ && !this.socket$.closed) {
      return true;
    } else {
      this.connectChatSocket(chat_type);
      return false;
    }
  }

  public close(): void {
    this.socket$?.complete();
  }

  // Profile data socket

  public connectProfileDetails(): void {
    const url = API.chat_profile_details_socket;
    const token = this._auth.getUserInfo().accessToken;
    const webSocketUrl = `${url}${encodeURIComponent(token)}/`;
    this.profileSocket$ = webSocket(webSocketUrl);
  }

  // public sendProfileDetails(message: any): void {
  //   this.profileSocket$.next(message);
  // }

  public receiveProfileDetails(): Observable<any> {
    return this.profileSocket$.asObservable();
  }

  public isProfileDetailsSocketConnected(): any {
    if (this.profileSocket$ && !this.profileSocket$.closed) {
      return true;
    } else {
      this.connectProfileDetails();
      return false;
    }
  }

  public closeProfileDetails(): void {
    this.profileSocket$?.complete();
  }

  // Notification socket

  public connectNotificationSocket(): void {
    const url = API.chat_notification_socket;
    const token = this._auth.getUserInfo().accessToken;
    const webSocketUrl = `${url}${encodeURIComponent(token)}/`;
    this.notificationSocket$ = webSocket(webSocketUrl);
  }

  public sendNotification(message: any): void {
    this.notificationSocket$.next(message);
  }

  public receiveNotification(): Observable<any> {
    return this.notificationSocket$.asObservable();
  }

  public isNotificationSocketConnected(): any {
    if (this.notificationSocket$ && !this.notificationSocket$.closed) {
      return true;
    } else {
      this.connectNotificationSocket();
      return false;
    }
  }

  public closeNotification(): void {
    this.notificationSocket$?.complete();
  }

  callRefreshToken(fromChat = false) {
    let refreshToken = this._auth.getUserInfo().refreshToken;
    this._auth.refreshToken(refreshToken).subscribe((data: any) => {
      let userInfo = this._auth.getUserInfo();
      userInfo.accessToken = data['tokens']['access'];
      userInfo.refreshToken = data['tokens']['refresh'];
      this._auth.setLoginDetailsToLocalStorage(userInfo);
      this._auth.activeOrInactive(true);
      if (fromChat) {
        this.connectAllSocket();
      }
    }, error => {
      this._auth.backToLogin();
    });
  }

  // SA
  public connectProfileDetailsForSA(): void {
    const url = API.chat_profile_details_socket_super_admin;
    const token = this._auth.getUserInfo().accessToken;
    const webSocketUrl = `${url}${encodeURIComponent(token)}/`;
    this.profileSocketSA$ = webSocket(webSocketUrl);
  }

  public receiveProfileDetailsForSA(): Observable<any> {
    return this.profileSocketSA$.asObservable();
  }

  public isProfileDetailsSocketConnectedForSA(): any {
    if (this.profileSocketSA$ && !this.profileSocketSA$.closed) {
      return true;
    } else {
      this.connectProfileDetailsForSA();
      return false;
    }
  }

  public closeProfileDetailsForSA(): void {
    this.profileSocketSA$?.complete();
  }

}
