import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderFooterModule } from './Pages/header-footer/header-footer.module';
import { WildCardPageComponent } from './Pages/wild-card-page/wild-card-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptorService } from './core/services/auth-interceptor.service';
import { TitleCasePipe } from '@angular/common';
import { MaterialStoreModule } from 'src/@moppetto/Material-Store/material-store.module';
import { MatIconRegistry } from '@angular/material/icon';
import { ToastsContainer } from "./core/ngb-toast/toasts-container.component";
import { RedirectUiComponent } from './Pages/redirect-ui/redirect-ui.component';
import { AuthGuard } from './core/auth.guard';

@NgModule({
    declarations: [
        AppComponent,
        WildCardPageComponent,
        RedirectUiComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HeaderFooterModule,
        HttpClientModule,
        MaterialStoreModule,
        ToastsContainer
    ],
    providers: [AuthGuard, CookieService, TitleCasePipe,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        MatIconRegistry
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
