<div class="mainContainerOuter">
    <div class="container">
        <div class="bodyOuter">
            <div class="bodyInner">
                <div class="left_part">
                    <img src="../../../../assets/Website assets/mail_reuse/test.PNG" alt="">
                </div>
                <div class="right_part">
                    <div class="right_part_inner text-center">
                        <div class="head_text">
                            <p class="m-0">You’re all good!</p>
                        </div>
                        <div class="sub_head_text">
                            <p class="m-0">Thank you for confirming your email.</p>
                        </div>
                        <div class="login_button">
                            <button mat-flat-button color="accent" (click)="goToLogin()">Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>