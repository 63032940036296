<div class="container  p-0">
    <div class="navbarOuter">
        <div class="menuAndSearch">
            <div class="smallMenu">
                <div class="navImg" routerLink="/home">
                    <img src="../../../../assets/Website assets/Logo/Moppetto-Header-Logo.svg" alt=""
                        class="largeDevice">
                    <img src="../../../../assets/Website assets/Logo/Moppetto_header_logo_small.svg" alt=""
                        class="smallDevice">
                </div>
            </div>
            <div class="searchOuter">
                <form class="search">
                    <div class="search__wrapper">
                        <input type="text" name="" placeholder="Search for..." class="search__field">
                        <button type="submit" class="fa fa-search search__icon"></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="profileIconOuter">
            <div class="categories">
                <div class="dropdown">
                    <button mat-button [matMenuTriggerFor]="categoriesMenu">
                        <p class="m-0">Categories</p>
                    </button>
                    <mat-menu #categoriesMenu="matMenu" xPosition="after" yPosition="below"
                        class="customize_categories_mat_menu">
                        <div class="categories" *ngFor="let category of categoriesList" (click)="routeToListing(category)">
                            <p class="m-0">{{category?.category?.name}}</p>
                        </div>
                        <!-- <div class="categories">
                            <p class="m-0">Language</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">S.T.E.M.</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">Arts</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">Fun</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">Sports</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">Business world</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">World savvy</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">Special intervention</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">Holiday camp</p>
                        </div>
                        <div class="categories">
                            <p class="m-0">Others</p>
                        </div> -->
                    </mat-menu>
                </div>
            </div>
            <div class="profile">
                <button mat-fab extended class="profile_inner_container" [matMenuTriggerFor]="profileMenu">
                    <mat-icon class="custom_mat_icon_person custom_mat_icon">person_outline</mat-icon>
                    <p class="m-0 profileName" [matTooltip]="userName" matTooltipPosition="right">{{userFirstName}}</p>
                </button>
                <mat-menu #profileMenu="matMenu" xPosition="after" yPosition="below" class="customize_profile_mat_menu">
                    <div class="show_permission_outer">
                        <p class="m-0 text-right happyMonkeyFont">{{userType}}</p>
                    </div>
                    <button mat-menu-item routerLink="/settings">
                        <mat-icon class="custom_mat_icon">people</mat-icon>
                        <span>Family</span>
                    </button>
                    <button mat-menu-item routerLink="/settings/dashboard">
                        <mat-icon class="custom_mat_icon">space_dashboard</mat-icon>
                        <span>Dashboard</span>
                    </button>
                    <button mat-menu-item routerLink="/settings/bookings">
                        <mat-icon class="custom_mat_icon">confirmation_number</mat-icon>
                        <span>Bookings</span>
                    </button>
                    <button mat-menu-item routerLink="/settings/favourites" *ngIf="isMediumScreen">
                        <mat-icon class="custom_mat_icon">favorite</mat-icon>
                        <span>Favourites</span>
                    </button>
                    <button mat-menu-item *ngIf="isMediumScreen" routerLink="/chat">
                        <mat-icon class="custom_mat_icon">chat</mat-icon>
                        <span>Chat</span>
                        <sup *ngIf="unreadMessageCount > 0">{{unreadMessageCount}}</sup>
                    </button>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon class="custom_mat_icon">logout</mat-icon>
                        <span>Logout</span>
                    </button>
                </mat-menu>
            </div>
            <div class="iconSection">
                <div class="locations iconOuter">
                    <button mat-mini-fab class="custom_fab" [matMenuTriggerFor]="locationMenu">
                        <mat-icon
                            class="material-symbols-outlined custom_fab_icon location_icon">apartment</mat-icon>
                    </button>
                    <mat-menu #locationMenu="matMenu" xPosition="after" yPosition="below"
                        class="customize_location_mat_menu">
                        <div class="show_permission_outer" *ngIf="selectedLocation">
                            <p class="m-0 text-right happyMonkeyFont">Current location :
                                <span>{{selectedLocation?.name}}</span>
                            </p>
                        </div>
                        <ng-cotainer *ngFor="let city of cityList">
                            <button mat-menu-item
                                [ngClass]="{'disabled_selected_city' : selectedLocation?.id === city?.id}"
                                (click)="selectLocation(city)">
                                <span>{{city?.name}}</span>
                            </button>
                        </ng-cotainer>
                    </mat-menu>
                </div>
                <button mat-mini-fab class="custom_fab iconOuter" *ngIf="!isMediumScreen" routerLink="/chat">
                    <mat-icon class="material-symbols-outlined custom_fab_icon chat_icon">chat</mat-icon>
                    <!-- <mat-icon class="material-symbols-outlined custom_fab_icon chat_icon">chat_bubble_outline</mat-icon> -->
                    <sup *ngIf="unreadMessageCount > 0">{{unreadMessageCount}}</sup>
                </button>
                <button mat-mini-fab class="custom_fab iconOuter favourite_icon_outer" routerLink="/settings/favourites"
                    *ngIf="!isMediumScreen">
                    <mat-icon
                        class="material-symbols-outlined custom_fab_icon favourite_icon">favorite_border</mat-icon>
                </button>
                <button mat-mini-fab class="custom_fab iconOuter" routerLink="/settings/cart">
                    <mat-icon class="material-symbols-outlined custom_fab_icon cart_icon">shopping_cart</mat-icon>
                    <sup *ngIf="totalCartVallue > 0">{{totalCartVallue}}</sup>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- <audio #audioPlayer muted src="../../../../assets/Website assets/Notification/Notification - Notification.mp3"></audio> -->

<audio>
    <source src="../../../../assets/Website assets/Notification/AudioSaga Bubble Pop 06.wav" />
</audio>