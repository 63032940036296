<div class="dialog_body_outer">
    <div class="head">
        <p class="">{{data?.header}}</p>
    </div>
    <div class="mainBody" *ngIf="!data?.isInnerHTML;else innerHTML">
        <p>{{data?.body}}</p>
    </div>
    <ng-template #innerHTML>
        <div class="mainBody" [innerHTML]="data?.body">
        </div>
    </ng-template>
</div>