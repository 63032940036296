import { environment } from "src/environments/environment";

export const API = {
    //Signup, Login, Password Reset
    register: `${environment.base_url}/authentication/register/`,
    register_manual_invited: `${environment.base_url}/authentication/invited/customer/register/`,
    login: `${environment.base_url}/authentication/login/`,
    logout: `${environment.base_url}/authentication/logout/`,
    email_exists: (email: string) => (`${environment.base_url}/email_exists?email=${email}`),
    refreshToken: `${environment.base_url}/authentication/token/refresh/`,
    google_sign_up: `${environment.base_url}/social_auth/google/`,
    google_sign_up_invited: `${environment.base_url}/authentication/invited/customer/register/`,
    facebook_sign_up: `${environment.base_url}/social_auth/facebook/`,
    password_reset: `${environment.base_url}/authentication/request-reset-email/`,
    city_update_1st_social_login: `${environment.base_url}/authentication/user/update_city`,
    email_token_verify: `${environment.base_url}/authentication/email-verify`,
    invited_token_verify: `${environment.base_url}/authentication/token-verify`,
    check_reset_password_token: (uuid: string, token: string) => (`${environment.base_url}/authentication/password-reset/${uuid}/${token}/`),
    reset_password: `${environment.base_url}/authentication/password-reset-complete`,

    // Family
    get_family: `${environment.base_url}/users/profile`,
    get_kids: `${environment.base_url}/users/kids`,
    add_kids: `${environment.base_url}/users/kids/`,
    edit_kids: (id: string) => (`${environment.base_url}/users/${id}/kid/`),
    delete_kids: (id: string) => (`${environment.base_url}/users/${id}/kid/`),
    edit_super_user_profile: `${environment.base_url}/users/update/superuser/profile/`,
    edit_user_profile: `${environment.base_url}/users/update/user/profile/`,
    update_user_permission: `${environment.base_url}/users/update/user/profile/`,
    delete_user: `${environment.base_url}/users/deleteUser/`,
    invite_user: `${environment.base_url}/users/addUser/`,
    cancel_invite_user: `${environment.base_url}/users/cancelInvite/`,
    get_permissions: `${environment.base_url}/users/permissions/`,
    get_avatars: `${environment.base_url}/avatars`,
    change_guardian_profile_image: `${environment.base_url}/authentication/user/update_profile_picture`,
    delete_kids_image: (id: string) => (`${environment.base_url}/authentication/user/delete_kid_profile_picture/${id}/`),
    delete_guardian_image: () => (`${environment.base_url}/authentication/user/delete_profile_picture`),



    // Listing
    get_list_of_attributes: `${environment.base_url}/activity_listing/attributes/`,
    get_list_of_ages: `${environment.base_url}/activity_listing/agegroups`,
    get_price_range_list: `${environment.base_url}/activity_listing/priceRange`,
    get_regions_list: `${environment.base_url}/activity_listing/regions/`,
    get_areas_list: `${environment.base_url}/activity_listing/areas/`,
    get_activity_list: `${environment.base_url}/activity_listing/`,
    post_like_unlike_toggle: (id: string) => (`${environment.base_url}/activity_listing/${id}/like_toggle/`),

    // Categories
    get_list_of_categories: `${environment.base_url}/categories/list`,

    // Activity
    get_activity_details: (id: string) => (`${environment.base_url}/activity_listing/${id}/`),
    get_list_of_activity_slots: (id: string) => (`${environment.base_url}/activity_listing/${id}/slots`),
    // get_list_of_activity_fixed_time_slots: (id: string) => (`${environment.base_url}/activity_listing/${id}/fixed_slots`),
    get_list_of_slots: (type: string, id: string) => (`${environment.base_url}/activity_listing/${id}/${type}`),
    add_to_cart: `${environment.base_url}/cart`,

    // Country and City
    countries_and_cities: `${environment.base_url}/countries/list`,
    get_cities: `${environment.base_url}/filters/cities`,
    update_city: `${environment.base_url}/authentication/user/temp_update_city`,

    // Cart
    get_cart_details: `${environment.base_url}/cart`,
    apply_coupon: (cart_item_id: number, coupon_value: string) => `${environment.base_url}/cart/coupon/${cart_item_id}/${coupon_value}`,
    remove_coupon: (cart_item_id: number) => `${environment.base_url}/cart/coupon/${cart_item_id}`,
    get_cart_count: `${environment.base_url}/cart/items/count`,

    // Carousal
    get_carousal_details: `${environment.base_url}/banners/web`,

    // Payment
    payment_request: `${environment.base_url}/v/generatePaymentLink/`,
    validate_payment: `${environment.base_url}/get_booking_status`,

    // Review
    get_or_submit_reviews: `${environment.base_url}/user/reviews`,

    // Booking
    get_bookings: `${environment.base_url}/bookings`,

    // Dashboard
    get_attributes_and_categories: `${environment.base_url}/user/dashboard/attributes_stars`,
    get_chart_data: `${environment.base_url}/user/dashboard/category_hours`,
    get_past_bookings_or_certificates: (type: string) => `${environment.base_url}/user/dashboard/${type}`,

    // Booking
    get_favourite_activities: `${environment.base_url}/activity_listing/`,

    // Feedback
    feedback_status: `${environment.base_url}/platform/review`,

    // Security
    check_password: `${environment.base_url}/users/checkPassword`,
    change_password: `${environment.base_url}/users/changePassword/`,

    // Vendor profile
    get_vendor_data: (id: string) => `${environment.base_url}/vendor/profile/details/${id}`,
    post_vendor_like_unlike_toggle: (id: string) => (`${environment.base_url}/vendor/profile/like_toggle/${id}`),
    get_vendor_profile_dropdown_details: (id: string) => `${environment.base_url}/vendor/profile/dropdown/${id}`,
    get_vendor_activities_listing: `${environment.base_url}/activity_listing/`,
    get_vendor_activities_reviews: (id: string) => `${environment.base_url}/vendor/profile/reviews/${id}`,

    // Vendor subscription
    get_subscription_details: `${environment.base_url}/vendor/subscription/plans`,
    get_subscription_coupon_details: `${environment.base_url}/coupons/`,
    vendor_subscription_payment: `${environment.base_url}/vendor/signup`,
    validate_vendor_subscription_status: `${environment.base_url}/get_subscription_status`,
    set_vendor_password: `${environment.base_url}/set_password`,

    //Chat
    get_activity_chats: `${environment.base_url}/activity-chats`,
    get_chat_messages: `${environment.base_url}/messages`,
    pin_chat: `${environment.base_url}/pin_chat/activity_chat`,
    unpin_chat: `${environment.base_url}/unpin_chat/activity_chat`,
    toggle_notification: `${environment.base_url}/toggle_notification/activity_chat`,
    mark_as_unread: `${environment.base_url}/mark_as_unread/activity_chat`,
    get_unread_notification_count: `${environment.base_url}/get_all_unread_uessages`,

    // Socket
    chat_socket: `${environment.socket_url}/chat/`,
    chat_profile_details_socket: `${environment.socket_url}/chat_status/activity_chat/`,
    chat_profile_details_socket_super_admin: `${environment.socket_url}/chat_status/superadmin_chat/`,
    chat_notification_socket: `${environment.socket_url}/notification/`,


    // SSE
    chat_notification_SSE: `${environment.base_url}/chat/notification`,

    // Policies
    get_terms_of_use: `${environment.base_url}/static_info/tou`,
    get_privacy_policy: `${environment.base_url}/static_info/pp`,
    get_terms_of_service: `${environment.base_url}/static_info/tos`,
    get_vendor_terms_of_service: `${environment.base_url}/vendor/tnc`,

    // Receipt
    get_receipts: (perPage: number, pageIndex: number) => `${environment.base_url}/receipts?perpage=${perPage}&page=${pageIndex}`,
    download_receipt: (id: string) => `${environment.base_url}/user/receipt/view/${id}`


}