import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
import { API } from 'src/app/core/constants/api.constant';
import { Router } from '@angular/router';
import { CookieServices } from 'src/app/cookie.service';
import { TokenDetails } from 'src/app/core/models/TokenDetails';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogingServiceService {

  private loginActive = new BehaviorSubject(false);
  public getLoginInfo = this.loginActive.asObservable();

  // private username = new BehaviorSubject("");
  // public getUserName = this.username.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookie: CookieServices
  ) {
    if (this.isLoggedIn()) this.loginActive.next(true);
  }

  signUp(data) {
    let url = API.register;
    return this.http.post(url, data);
  }

  signUpManulInvited(data) {
    return this.http.post(`${API.register_manual_invited}`, data);
  }

  login(data) {
    return this.http.post(`${API.login}`, data);
  }

  emailExists(data) {
    return this.http.get(`${API.email_exists(data)}`);
  }

  activeOrInactive(data) {
    // if (this.isLoggedIn()) {
    //   this.loginActive.next(true);
    //   return;
    // }
    this.loginActive.next(data);
  }

  // setUserName(data) {
  //   this.username.next(data);
  // }

  // refreshToken(data) {
  //   return this.http.post(`${this.refreshToken}`, { "refresh": data });
  // }

  getCityCounry() {
    return this.http.get(`${API.countries_and_cities}`);
  }

  singUpWithGoogle(token, isInvited?: boolean) {
    if (isInvited) {
      return this.http.post(`${API.google_sign_up_invited}`, { "social_auth_token": token });
    }
    return this.http.post(`${API.google_sign_up}`, { "auth_token": token });
  }

  singUpWithFaceBook(token) {
    return this.http.post(`${API.facebook_sign_up}`, { "auth_token": token });
  }

  resetPasswordRequest(data) {
    return this.http.post(`${API.password_reset}`, data);
  }

  expireToken(token) {
    return this.http.post(`${API.logout}`, {
      refresh: token
    });
  }

  logout() {
    this.expireToken(this.getUserInfo().refreshToken).subscribe((res: any) => {
      if (res) {
        this.backToLogin();
      }
    }, (err: any) => {
      this.backToLogin();
    });
    // this.activeOrInactive(false);
    // let navigateTo = (localStorage.getItem('role') === 'CUSTOMER') ? '/' : '';
    // localStorage.clear();
    // sessionStorage.clear();
    // this.router.navigate([navigateTo]);
    // this.cookie.deleteAll();
    // this.activeOrInactive(false);
    // let navigateTo = (localStorage.getItem('role') === 'CUSTOMER') ? '/' : '';
    // this.router.navigate([navigateTo]);
  }

  setLoginDetailsToLocalStorage(token_details) {
    localStorage.setItem("user_data", JSON.stringify(token_details));
  }

  getLoginDetailsToLocalStorage() {
    return JSON.parse(localStorage.getItem("user_data"));
  }

  getUserInfo() {
    let userInfo: TokenDetails;
    userInfo = TokenDetails.getInstance(this.getLoginDetailsToLocalStorage());
    return userInfo;
  }

  isLoggedIn(): boolean {
    return !this.getUserAccess() ? false : true;
  }

  hasPermissionDetails(): boolean {
    try {
      const permission = JSON.parse(localStorage.getItem('permission_types'));
      return (permission && permission[0]);
    } catch (error) {
      return false;
    }
  }

  getUserAccess() {
    return this.getUserInfo().accessToken && this.getUserInfo().refreshToken && this.getUserInfo().role === 'CUSTOMER' && this.hasPermissionDetails();
  }

  backToLogin(isNavigate?: boolean) {
    localStorage.clear();
    sessionStorage.clear();
    this.cookie.deleteAll();
    this.activeOrInactive(false);
    if (!isNavigate) {
      this.router.navigate(['/']);
    }
  }

  // refreshToken2(body: any): Observable<any> {
  //   return this.http.post(API.refreshToken, body)
  //     .pipe(
  //       tap((res: any) => {
  //         let userInfo = this.getUserInfo();
  //         userInfo.accessToken = res.access;
  //         this.setLoginDetailsToLocalStorage(userInfo);
  //       }),
  //       catchError(this.handleError));
  // }

  // handleError(error: HttpErrorResponse): any {
  //   this.backToLogin();
  // }

  refreshToken(token: string) {
    return this.http.post(API.refreshToken, {
      refresh: token
    });
  }

  navigateAgterLogin() {
    const shared_url = localStorage.getItem('shared_url') || null;
    if (shared_url && shared_url !== '' && shared_url !== "/") {
      this.router.navigateByUrl(shared_url);
      localStorage.removeItem('shared_url');
    }
    else {
      this.router.navigate(["/home"]);
      localStorage.removeItem('shared_url');
    }
  }


  cityUpdate1stTimeSocialLogin(token, payload) {
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.post(API.city_update_1st_social_login, payload, { headers });
  }

  checkEmailTokenValidOrNot(token) {
    return this.http.get(`${API.email_token_verify}?token=${token}`);
  }

  checkInvitedEmailTokenValidOrNot(token) {
    return this.http.get(`${API.invited_token_verify}?token=${token}`);
  }


  public initilizationOfFireBase() {
    const app = initializeApp(environment.firebase);
    return app;
  }

  public getInitilizationOfFireBase() {
    const auth = getAuth(this.initilizationOfFireBase());
    return auth;
  }

  public getGoogleAuthProvider() {
    const provider = new GoogleAuthProvider();
    return provider;
  }

  public backToLoginIsNotLoggedIn() {
    this.backToLogin(true);
    const currentUrl = this.router.url;
    localStorage.setItem('shared_url', currentUrl);
    this.router.navigate(['/']);
  }



}
