export enum USER_TYPE {
    SUPER_USER = 'Super User',
    USER = 'User',
}

export class PERMISSION {
    CHILD_ENROLMENT: number | string;
    VIEWING_RIGHTS: number | string;
    SUPER_USER: number | string;
    constructor() {
        let permission_types = JSON.parse(localStorage.getItem('permission_types'));
        this.CHILD_ENROLMENT = (permission_types?.find(e => { return e?.name === 'Child Enrolment' }))?.id;
        this.VIEWING_RIGHTS = (permission_types?.find(e => { return e?.name === 'Viewing Rights' }))?.id;
        this.SUPER_USER = (permission_types?.find(e => { return e?.name === 'Super User' }))?.id
    }
}

export enum DEFAULT {
    PROFILE_IMAGE = '/assets/Website assets/KidsPages/user.svg',
    DUMMY_BLANK_IMAGE = '/assets/Website assets/Listing/blank_dummy_image.png'
}

export enum SCREEN_SIZES {
    XLARGE = 1199,
    LARGE = 991,
    MEDIUM = 767,
    SMALL = 575
}

export enum ACTIVITY_TYPE {
    DAY_ACCESS = 'Day Access',
    OPEN = 'Open Activity',
    TERM = 'Term Activity',
    FIXED_TIMING = 'Fixed Time'
}

export enum Subscription_Plans {
    BASIC = 'BASIC',
    ADVANCE = 'ADVANCE',
    PREMIUM = 'PREMIUM',
    ENTERPRISE = 'ENTERPRISE'
}

export enum Chat_Topic {
    TRIAL = 'TRL',
    ENQUIRY = 'ENQ'
}