import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getPermission() {
    return this.http.get(`${API.get_permissions}`);
  }

  getCities() {
    return this.http.get(`${API.get_cities}`);
  }

  getCartCount() {
    return this.http.get(`${API.get_cart_count}`);
  }

  updateCity(payload) {
    let url = `${API.update_city}`;
    return this.http.put(url, payload);
  }

  getTermsOfUse() {
    let url = `${API.get_terms_of_use}`;
    return this.http.get(url);
  }

  getPrivacyPolicy() {
    let url = `${API.get_privacy_policy}`;
    return this.http.get(url);
  }

  getTermsOfService() {
    let url = `${API.get_terms_of_service}`;
    return this.http.get(url);
  }

}