import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'src/app/core/constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  getActivityChats(topic: string, perpage) {
    let url = `${API.get_activity_chats}?perpage=${perpage}`;
    if (topic) {
      url += `&topic=${topic}`
    }
    return this.http.get(url);
  }

  createChat(payload) {
    let url = `${API.get_activity_chats}`;
    return this.http.post(url, payload);
  }

  getSingleActivityChat(id) {
    let url = `${API.get_activity_chats}/${id}`;
    return this.http.get(url);
  }

  getChatMessages(chat, perpage) {
    let url = `${API.get_chat_messages}?chat_id=${chat.id}&chat_type=${chat.chat_type}&perpage=${perpage}&topic=${chat.topic}`;
    return this.http.get(url);
  }

  deleteActivityChat(id) {
    let url = `${API.get_activity_chats}/${id}`;
    return this.http.delete(url);
  }

  pinChat(id) {
    let url = `${API.pin_chat}/${id}`;
    return this.http.post(url, '');
  }

  unPinChat(id) {
    let url = `${API.unpin_chat}/${id}`;
    return this.http.post(url, '');
  }

  markAsUnread(id) {
    let url = `${API.mark_as_unread}/${id}`;
    return this.http.post(url, '');
  }

  toggleNotification(id) {
    let url = `${API.toggle_notification}/${id}`;
    return this.http.post(url, '');
  }

  getUnreadNotificationCount() {
    let url = `${API.get_unread_notification_count}`;
    return this.http.get(url);
  }

}
