import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { PERMISSION, SCREEN_SIZES, USER_TYPE } from '../constants/app.constant';
import { ApiService } from './api.service';
import { LogingServiceService } from 'src/app/Pages/login-sign-up/loging-service.service';
import { Router } from '@angular/router';
import { ToastService } from '../ngb-toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  private spinnerSubject = new Subject<any>();
  public spinerObs = this.spinnerSubject.asObservable();

  private PERMISSION = new PERMISSION();

  public cartValue = new BehaviorSubject<any>(0);
  public cartValueObs = this.cartValue.asObservable();

  constructor(
    private _apiService: ApiService,
    private _auth: LogingServiceService,
    private _router: Router,
    private _toast: ToastService
  ) {
  }

  spinnerTriggered(event: boolean) {
    this.spinnerSubject.next(event);
  }

  cartValueTriggered(event: number) {
    this.cartValue.next(event);
  }

  isAdmin(data) {
    let email = this._auth.getUserInfo().emailId;
    return data === email ? true : false;
  }

  // checkPermission(userType?) {
  //   let permission;
  //   if (userType) {
  //     permission = userType;
  //   } else {
  //     permission = this._auth.getUserInfo().permissionRights;
  //   }
  //   return permission === USER_TYPE.SUPER_USER ? this.PERMISSION.SUPER_USER : permission === USER_TYPE.USER ? this.PERMISSION.CHILD_ENROLMENT : this.PERMISSION.VIEWING_RIGHTS;
  // }

  // checkPermission(userType?) {
  //   let permission;
  //   // If userType is not provided, get it from auth
  //   if (!userType) {
  //     userType = this._auth.getUserInfo().permissionRights?.id;
  //   }
  //   // Determine permission based on user type
  //   if (userType === USER_TYPE.SUPER_USER) {
  //     permission = this.PERMISSION.SUPER_USER;
  //   } else if (userType === USER_TYPE.USER) {
  //     permission = this.PERMISSION.CHILD_ENROLMENT;
  //   } else {
  //     permission = this.PERMISSION.VIEWING_RIGHTS;
  //   }
  //   return permission;
  // }


  checkPermission(permission?) {
    this.PERMISSION = new PERMISSION();
    if (!permission) {
      permission = this._auth.getUserInfo().permissionRights?.id;
    };
    if (permission === this.PERMISSION.SUPER_USER) {
      return this.PERMISSION.SUPER_USER;
    } else if (permission === this.PERMISSION.CHILD_ENROLMENT) {
      return this.PERMISSION.CHILD_ENROLMENT;
    } else {
      return this.PERMISSION.VIEWING_RIGHTS;
    }
  }


  checkUserType(permission_value) {
    this.PERMISSION = new PERMISSION();
    return permission_value === this.PERMISSION.SUPER_USER ? USER_TYPE.SUPER_USER : USER_TYPE.USER;
  }

  checkPermissionType(permission_value) {
    this.PERMISSION = new PERMISSION();
    return permission_value === this.PERMISSION.SUPER_USER ? 'SUPER_USER' : permission_value === this.PERMISSION.CHILD_ENROLMENT ? 'CHILD_ENROLMENT' : 'VIEWING_RIGHTS';
  }

  currentInnerWidth: number;
  setDynamicImages(data, apiKey, newkey, image) {
    this.currentInnerWidth = window.innerWidth;
    if ((this.currentInnerWidth > SCREEN_SIZES.LARGE)) {
      data?.forEach(e => { e[newkey] = e?.[apiKey]?.large || image });
    }
    else if ((this.currentInnerWidth <= SCREEN_SIZES.LARGE) && (this.currentInnerWidth > SCREEN_SIZES.SMALL)) {
      data?.forEach(e => { e[newkey] = e?.[apiKey]?.medium || image });
    } else {
      data?.forEach(e => { e[newkey] = e?.[apiKey]?.small || image });
    }
  }

  getCartCount() {
    this._apiService.getCartCount().subscribe((res: any) => {
      if (res) {
        this.cartValueTriggered(res?.cart_item_count);
      }
    })
  }

  getPermissions(): Observable<boolean> {
    return new Observable(observer => {
      this._apiService.getPermission().subscribe(
        (res: []) => {
          if (res?.length) {
            localStorage.removeItem('permission_types');
            localStorage.setItem('permission_types', JSON.stringify(res));
            this._auth.navigateAgterLogin();
            observer.next(true);
            observer.complete();
          } else {
            this.handlePermissionError();
            observer.error();
          }
        },
        (err) => {
          this.handlePermissionError();
          observer.error(err);
        }
      );
    });
  }

  private handlePermissionError() {
    this.spinnerTriggered(false);
    this._toast.errorToast('Something went wrong, please try after sometime!');
    this._auth.backToLogin();
  }

  async downLoadFile(url, type) {
    let fileName = url.replace(/^.*[\\\/]/, '');
    // if (type === 'pdf') {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
    link.remove();
    // } else if (type === 'image') {
    // let imageUrl = url;
    // this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
    //   let base64Image = "data:image/jpg;base64," + base64data;
    //   // save image to disk
    //   var link = document.createElement("a");
    //   document.body.appendChild(link); // for Firefox
    //   link.setAttribute("href", base64Image);
    //   link.setAttribute("download", "mrHankey.webp");
    //   link.click();
    // });
    // let base64Data: any = this.getBase64ImageFromURL(url);
    // let base64Data: any;
    // this.getBase64FromURL(url, function (base64Data) {
    //   const src = base64Data;
    //   const link = document.createElement("a")
    //   link.href = src;
    //   link.download = fileName
    //   link.click();
    //   link.remove();
    // })
    // }
  }



  getBase64FromURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }



  // getBase64ImageFromURL(url: string) {
  //   return Observable.create((observer: Observer<string>) => {
  //     const img: HTMLImageElement = new Image();
  //     img.crossOrigin = "Anonymous";
  //     img.src = url;
  //     if (!img.complete) {
  //       img.onload = () => {
  //         observer.next(this.getBase64Image(img));
  //         observer.complete();
  //       };
  //       img.onerror = err => {
  //         observer.error(err);
  //       };
  //     } else {
  //       observer.next(this.getBase64Image(img));
  //       observer.complete();
  //     }
  //   });
  // }

  // getBase64Image(img: HTMLImageElement) {
  //   const canvas: HTMLCanvasElement = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);
  //   const dataURL: string = canvas.toDataURL("image/png");
  //   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  // }

  // async  toDataURL(url) {
  //   const blob = await fetch(url).then(res => res.blob());
  //   return URL.createObjectURL(blob);
  // }

  // toDataURL = async (url) => {
  //   var res = await fetch(url);
  //   var blob = await res.blob();

  //   const result = await new Promise((resolve, reject) => {
  //     var reader = new FileReader();
  //     reader.addEventListener("load", function () {
  //       resolve(reader.result);
  //     }, false);

  //     reader.onerror = () => {
  //       return reject(this);
  //     };
  //     reader.readAsDataURL(blob);
  //   })

  //   return result
  // };

  // async toDataUrl7(url) {
  //   const data = await fetch(url);
  //   const blob = await data.blob();
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       resolve(base64data);
  //     }
  //   });
  // }

  // async  downloadCertificate(url) {
  //   const a = document.createElement("a");
  //   a.href = await this.toDataURL(url);
  //   a.download = "myImage";
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  autoLogin() {
    this._auth.activeOrInactive(true);
    this.getCartCount();
  }

}
