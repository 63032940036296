import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LogingServiceService } from '../Pages/login-sign-up/loging-service.service';
import { ApiService } from './services/api.service';
import { GenericService } from './services/generic.service';
import { ToastService } from './ngb-toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _auth: LogingServiceService,
    private _toast: ToastService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._auth.isLoggedIn()) {
      return true;
    } else {
      this._auth.backToLogin();
      this._toast.errorToast('Please login to access this URL', 'Login');
      localStorage.setItem('shared_url', state.url);
      return false;
    }
  }

}
