<div class="mainOuter container-fluid border-bottom">
    <div class="container pt-3 pb-1">
        <div class="navOuter">
            <div class="navs">
                <div class="navImg">
                    <a routerLink="/">
                        <img src="../../../../assets/Website assets/Logo/Logo.svg" alt="" class="largeDevice">
                        <img src="../../../../assets/Website assets/Logo/Logo_only_M.PNG" alt="" class="smallDevice">
                    </a>
                </div>
            </div>

            <div class="navs">
                <div class="tabs" routerLink="/home" routerLinkActive="activatedTab">
                    <p class="m-0">Home</p>
                </div>
            </div>

            <div class="navs">
                <div class="tabs" routerLink="/vendor/home" routerLinkActive="activatedTab">
                    <p class="m-0">Why Moppetto</p>
                </div>
            </div>

            <div class="navs">
                <div class="tabs" routerLink="/vendor/plans-and-pricing" routerLinkActive="activatedTab">
                    <p class="m-0">Join us</p>
                </div>
            </div>
            <div class="navs">
                <div class="forLoginButton">
                    <button color="accent" mat-flat-button type="button" [routerLink]="loginButton?.['route']"
                        [queryParams]="loginButton?.qParam">
                        {{ loginButton?.['text'] }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>