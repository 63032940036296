<div class="container">
    <div class="mainOuter">
        <div class="errorImgWithText" data-aos="fade-left" data-aos-delay="200" data-aos-duration="200"
            data-aos-easing="ease-in">
            <div class="errorImg">
                <img src="../../../assets/Website assets/ErrorPage/errorPage.svg" alt="" class="d-clock mx-auto">
            </div>
            <div class="errorText">
                <p class="m-0">404</p>
            </div>
        </div>
        <div class="bottomSection" data-aos="fade-right" data-aos-delay="200" data-aos-duration="200"
            data-aos-easing="ease-in">
            <div class="blueText">
                <p class="m-0 text-center">Ahh, we don’t have anything here.</p>
            </div>
            <div class="homeButton">
                <button mat-flat-button color="primary" routerLink="../">Back To Home</button>
            </div>
        </div>
    </div>
</div>