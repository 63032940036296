import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderFooterRoutingModule } from './header-footer-routing.module';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ExtraNavbarComponent } from './extra-navbar/extra-navbar.component';
import { CarouselComponent } from './carousel/carousel.component';
import { MaterialStoreModule } from 'src/@moppetto/Material-Store/material-store.module';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    ExtraNavbarComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    HeaderFooterRoutingModule,
    MaterialStoreModule
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    ExtraNavbarComponent,
    CarouselComponent
  ]
})
export class HeaderFooterModule { }
