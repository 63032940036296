import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { RedirectUiComponent } from '../redirect-ui/redirect-ui.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const routes: Routes = [
  {
    path: 'redirect-to',
    resolve: {
      url: externalUrlProvider,
    },
    component: RedirectUiComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.queryParamMap.get('externalUrl');
        setTimeout(() => {
          window.open(externalUrl, '_self');
        }, 1000);

      },
    }
  ],
  exports: [RouterModule]
})
export class HeaderFooterRoutingModule { }
