export class TokenDetails {
    accessToken: string;
    refreshToken: string;
    emailId: string;
    role: any;
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    permissionRights: any;
    profilePic: any;
    city: any;
    constructor(
        accessToken: string,
        refreshToken: string,
        emailId: string,
        role: string,
        userId: number,
        userName: string,
        firstName: string,
        lastName: string,
        permissionRights: any,
        profilePic: any,
        city: any
    ) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.emailId = emailId;
        this.role = role;
        this.userId = userId;
        this.userName = userName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.permissionRights = permissionRights;
        this.profilePic = profilePic;
        this.city = city;
    }
    static getInstance(model) {
        return new TokenDetails(
            model?.accessToken,
            model?.refreshToken,
            model?.emailId,
            model?.role,
            model?.userId,
            model?.userName,
            model?.firstName,
            model?.lastName,
            model?.permissionRights,
            model?.profilePic,
            model?.city
        );
    }


}