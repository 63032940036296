import { Component, OnInit, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { LogingServiceService } from "../../login-sign-up/loging-service.service";
import { NavigationEnd, Router } from "@angular/router";
import { CookieServices } from "../../../cookie.service"
import { Subscription, interval } from 'rxjs';
import { GenericService } from 'src/app/core/services/generic.service';
import { ApiService } from 'src/app/core/services/api.service';
import { SCREEN_SIZES } from 'src/app/core/constants/app.constant';
import { filter, take } from 'rxjs/operators';
import { SSEService } from 'src/app/core/services/sse.service';
import { WebsocketService } from '../../chat/services/websocket.service';
import { ToastService } from 'src/app/core/ngb-toast/toast.service';
import { ChatService } from '../../chat/services/chat.service';
import { CategoriesService } from '../../homepage/services/categories.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  searchInput: string;
  loginActive = false;
  username;
  cartSubscription: Subscription;
  totalCartVallue: number = 0;
  userType;
  private currentInnerWidth: number;
  public isMediumScreen: boolean = false;
  public cityList: any;
  public selectedLocation: any;
  private currentRoute: any;
  public userName: string;
  public userFirstName: string;
  private sseSubscription: Subscription;
  private chatNotificationSubscription: Subscription;
  // @ViewChild('audioPlayer') audioPlayer: ElementRef;
  public audioElement: HTMLAudioElement;
  // audioInterval = interval(1000);
  // audioIntervalDestroyer: Subscription;
  public interval = interval(2000);
  private intervalDestroyer: Subscription;
  public unreadMessageCount: number = 0;
  private errorCount: number = 1;
  private maxErrorCount: number = 4;
  public categoriesList: any[] = [];


  constructor(
    private router: Router,
    private generic: GenericService,
    private apiService: ApiService,
    private _auth: LogingServiceService,
    private _SSE: SSEService,
    private _websocket: WebsocketService,
    private _toast: ToastService,
    private _chat: ChatService,
    private categoriesService: CategoriesService
  ) {
    this._websocket.connectNotificationSocket();
  }

  ngAfterViewInit(): void {
    this.subscribeNotificationSubscription();
    this.getListOfCategories();
  }

  private getUnreadNotificationCount() {
    this._chat.getUnreadNotificationCount().subscribe((res: any) => {
      this.unreadMessageCount = res?.count || 0;
    }, (err) => {
      this.unreadMessageCount = 0;
    })
  }

  private subscribeNotificationSubscription() {
    this.chatNotificationSubscription = this._websocket.receiveNotification().subscribe(
      (message) => {
        console.log('message navbar', message);
        this.intervalDestroyer?.unsubscribe();
        // message = {
        //   "type": "notification",
        //   "notification": true,
        //   "unread_message_count": 2,
        //   "latest_message": {
        //     "from": "Vendor Four",
        //     "message_body": "How are you oing?"
        //   }
        // }
        if (message.type === 'notification') {
          if (message?.unread_message_count > this.unreadMessageCount && message?.notification) {
            this.unreadMessageCount = message?.unread_message_count;
            this.audioPlay();
            if (message?.latest_message?.message_body && message?.latest_message?.from) {
              const last_latest_message = JSON.parse(localStorage.getItem('last_latest_message'))
              if (message?.latest_message?.message_body === last_latest_message?.message_body && message?.latest_message?.from === last_latest_message?.from && last_latest_message?.isShow === 'false') {
                const latest_message = message?.latest_message;
                latest_message['isShow'] = 'true';
                localStorage.setItem('last_latest_message', JSON.stringify(latest_message));
              } else {
                this._toast.infoToast((message?.latest_message?.message_body?.length > 100 ? message?.latest_message?.message_body?.slice(0, 99) + '...' : message?.latest_message?.message_body), message?.latest_message?.from)
                const latest_message = message?.latest_message;
                latest_message['isShow'] = 'false';
                localStorage.setItem('last_latest_message', JSON.stringify(latest_message));
              }
            }
          } else {
            this.unreadMessageCount = message?.unread_message_count || 0;
          }
        }
      },
      (error) => {
        console.log('error navbar', error);
        // if (this.errorCount < this.maxErrorCount) {
        // console.log(error);
        // this._websocket.callRefreshToken(true);
        // this.checkRefreshTokenInterval();
        // this.errorCount++;
        // } else if (this.errorCount >= this.maxErrorCount) {
        // this._toast.errorToast("Something went worng, please try after sometime jkhgbhkhkjhkhkjhk!!");
        // this.intervalDestroyer?.unsubscribe();
        // return;
        // }
        if (this.errorCount <= this.maxErrorCount) {
          this.checkRefreshTokenInterval();
          this.errorCount++;
        } else {
          this.intervalDestroyer?.unsubscribe();
          this._toast.errorToast("Something went worng, unable to connect, your notification for chat might not work!!");
          return;
        }

      }
    );
  }

  private checkRefreshTokenInterval() {
    // this.subscribeNotificationSubscription();
    if (this.errorCount <= this.maxErrorCount) {

      this.intervalDestroyer?.unsubscribe();
      this.intervalDestroyer = this.interval.subscribe((res) => {
        this.subscribeNotificationSubscription();
      })
    } else {
      // this._toast.errorToast("Something went worng, please try after sometime!!");
      // this.intervalDestroyer?.unsubscribe();
      // return
    }

    // const intervalLimit = 5; // Set the number of times to call the interval
    // this.interval = this.interval.pipe(
    //   take(intervalLimit) // Use the take operator to limit the number of executions
    // );
    // this.intervalDestroyer = this.interval.subscribe((res) => {
    //   this.subscribeNotificationSubscription();
    // });
  }

  audioPlay() {
    this.audioElement = document.querySelector('audio');
    this.audioElement.load();
    this.audioElement.play();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.checkIsMediumDevice();
  }

  ngOnInit(): void {
    this.checkIsMediumDevice();
    this.loginActive = true;
    // this.connectSSE();
    this.getCitiesList();
    this.userName = this._auth.getUserInfo().firstName + ' ' + this._auth.getUserInfo().lastName;
    this.userFirstName = this._auth.getUserInfo().firstName;
    this.cartSubscription = this.generic.cartValueObs.subscribe(event => {
      this.totalCartVallue = event;
    });
    this.userType = this._auth.getUserInfo().permissionRights?.name;
    this.getUnreadNotificationCount();
  }

  public checkIsMediumDevice(): void {
    this.currentInnerWidth = window.innerWidth;
    if (this.currentInnerWidth <= SCREEN_SIZES.MEDIUM) {
      this.isMediumScreen = true;
    } else {
      this.isMediumScreen = false;
    }
  }

  logout() {
    this.clearAndDestroy();
    this._auth.logout();
  }

  private getCitiesList(): void {
    this.apiService.getCities().subscribe((res: any) => {
      if (res) {
        this.cityList = res;
        this.selectedLocation = this._auth.getUserInfo().city;
        this.isSelectedCityItem();
      }
    }, (err) => {

    })
  }

  public selectLocation(city): void {
    this.generic.spinnerTriggered(true);
    const payload = { city_id: city?.id }
    this.apiService.updateCity(payload).subscribe((res: any) => {
      this.generic.spinnerTriggered(false);
      let storage_details = JSON.parse(localStorage.getItem('user_data'));
      storage_details.city = city;
      localStorage.setItem('user_data', JSON.stringify(storage_details));
      this.selectedLocation = this._auth.getUserInfo().city;
      this.isSelectedCityItem();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([], {
        queryParamsHandling: 'merge',
      });
    }, (err) => {
      this._toast.errorToast("Something went worng, please try after sometime!!");
      this.generic.spinnerTriggered(false);
    })

  }

  public isSelectedCityItem(): void {
    if (this.selectedLocation) {
      let index = this.cityList.findIndex((e) => { return e.id === this.selectedLocation?.id });
      if (index !== -1) {
        this.cityList.unshift(this.cityList.splice(index, 1)[0]);
      }
    }
  }

  // public connectSSE() {
  //   // const url = 'your-sse-endpoint-url';
  //   this.sseSubscription = this._SSE.connect().subscribe(
  //     (event: MessageEvent) => {
  //       // Handle received event data
  //       console.log('Received message:', event.data);
  //     },
  //     (error) => {
  //       // Handle SSE connection error
  //       console.error('SSE connection error:', error);
  //     }
  //   );
  // }

  private getListOfCategories() {
    this.categoriesService.getListOfCategories().subscribe(((res: any) => {
      this.categoriesList = res || [];
    }),
      (err) => {
      })
  }

  public routeToListing(categoryData) {
    let data = window.btoa(JSON.stringify(categoryData));
    this.router.navigate([('listing')], { queryParams: { type: 'category', data: data, age: 'All' } })
  }

  private clearAndDestroy() {
    this.intervalDestroyer?.unsubscribe();
    this.chatNotificationSubscription.unsubscribe();
    this._websocket.closeNotification();
    this._websocket.closeProfileDetails();
    this._websocket.close();
  }

  ngOnDestroy() {
    this.clearAndDestroy();
  }

}
