import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationStart, RouteConfigLoadStart } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-extra-navbar',
  templateUrl: './extra-navbar.component.html',
  styleUrls: ['./extra-navbar.component.scss']
})
export class ExtraNavbarComponent implements OnInit {

  tabs = {
    home: false,
    whyMoppetto: false,
    joinUsTab: false
  }

  vPanelLoginURL = environment.vPanelLoginURL;
  loginButton: any = {
    text: 'Vendor log in',
    route: 'redirect-to',
    qParam: { externalUrl: this.vPanelLoginURL }
  };

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

    this.router.events.subscribe((event: RouterEvent) => {

      if (event instanceof RouteConfigLoadStart) {
        if (event?.['route']?.['path'] === "") {
          this.loginButton = {
            text: 'Vendor log in',
            route: 'redirect-to',
            qParam: { externalUrl: this.vPanelLoginURL }
          }
        }
      }

      if (event instanceof NavigationStart && event?.['url'] === '/') {
        this.loginButton = {
          text: 'Vendor log in',
          route: 'redirect-to',
          qParam: { externalUrl: this.vPanelLoginURL }
        }
      }

      if (event instanceof NavigationStart && event?.['url'] !== '/') {
        this.loginButton = {
          text: "Log in",
          route: "/",
        }
      }

    });

  }

}
